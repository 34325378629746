<template>
  <div>
    <div class="nav">
      <div
        v-for="(item, index) in nav"
        :key="index"
        class="navItem"
        @click="navClick(index)"
      >
        <div class="navItem-name">{{ item.name }}</div>
        <div class="nav-underline" v-if="index == navIndex"></div>
      </div>
    </div>

    <div v-if="navIndex == 0">
      <div class="select flex flex-center">
        <div
          class="selectItem flex-item_center"
          @click="selectClick(index)"
          :class="selectIndex == index ? 'selctActive' : ''"
          v-for="(item, index) in selectArray"
          :key="index"
        >
          {{ item }}
        </div>
      </div>

      <div
        class="taskContent"
        v-for="(item, index) in taskList"
        :key="index"
        @click="router(item)"
      >
        <div class="taskContent-top flex flex-center">
          <div class="taskContent-top_title">{{ item.work_task.name }}</div>
          <!-- <div class="taskContent-top_btn flex-item_center">待办</div> -->
        </div>
        <div class="taskContent-all">
          <div class="flex flex-center" style="justify-content: space-between;">
            <div class="flex flex-center">
              <!-- <div class="taskContent-all_title">发起人: 张东海</div> -->
              <div class="taskContent-all_btn flex-item_center">任务</div>
            </div>
            <div class="taskContent-detail flex flex-center">
              <div>详情</div>
              <img
                class="taskContent-icon"
                src="../assets/imgs/arrow.png"
                alt=""
              />
            </div>
          </div>
          <div class="taskContent-time">
            发布时间: {{ item.work_task.start_date }}
          </div>
          <div class="flex flex-center" style="justify-content: space-between;">
            <div class="taskContent_time">
              截止时间: {{ item.work_task.end_date }}
            </div>
            <div class="taskContent-status" v-if="item.status == 1">已完成</div>
            <div
              class="taskContent-status"
              style="color:#ED184F;"
              v-if="item.status == 0"
            >
              未完成
            </div>
            <!-- <div
              class="taskContent-status"
              style="color:#FF5A1C;"
              v-if="item.status == 1"
            >
              已完成
            </div> -->
          </div>
        </div>
      </div>
    </div>

    <div v-if="navIndex == 1">
      <div v-if="messList.length > 0">
        <div class="taskContent" v-for="(item, index) in messList" :key="index">
          <div class="taskContent-top flex flex-center">
            <div class="taskContent-top_title">{{ item.content }}</div>
          </div>
          <div class="taskContent-all">
            <div
              class="flex flex-center"
              style="justify-content: space-between;"
            >
              <div class="flex flex-center">
                <div class="taskContent-all_title">
                  发起人: {{ item.sender }}
                </div>
                <div class="taskContent-all_btn flex-item_center">提醒</div>
              </div>
              <div class="taskContent-detail flex flex-center">
                <div>详情</div>
                <img
                  class="taskContent-icon"
                  src="../assets/imgs/arrow.png"
                  alt=""
                />
              </div>
            </div>
            <div class="taskContent-time">
              <!-- 创建时间: {{ item.send_time }} -->
            </div>
            <div
              class="flex flex-center"
              style="justify-content: space-between;"
            >
              <div class="taskContent_time">创建时间: {{ item.send_time }}</div>
              <div
                class="taskContent-status"
                v-if="item.is_send == 0"
                style="color:#ED184F;"
              >
                未完成
              </div>
              <div class="taskContent-status" v-else>已完成</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="padding-b"></div>
    <!-- 底部固定 -->
    <div class="bottom fixed flex">
      <div class="bottom-add flex-item_center" @click="add()">
        添加
      </div>
    </div>
  </div>
</template>

<script>
import * as request from "../api/data";
import _ from "lodash";
import { Toast } from "vant";

export default {
  data() {
    return {
      nav: [{ name: "任务" }, { name: "提醒" }],
      navIndex: 0,
      selectArray: ["全部", "待完成", "已完成"],
      selectIndex: 0,
      page: 1,
      taskList: [],
      status: 2,
      messList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 提醒
    async getMessList() {
      let res = await request._messWorkList({
        userid: localStorage.getItem("userid"),
      });
      console.log("提醒列表", res);
      if (_.toInteger(res.code) === 1) {
        this.messList = res.data;
      }
    },
    router(item) {
      console.log("item", item);
      this.$router.push({
        path: "/TaskDetail",
        query: {
          work_task_id: item.work_task_id,
        },
      });
    },
    selectClick(index) {
      this.selectIndex = index;
      let that = this;
      if (index == 0) {
        this.status = 2;
      } else if (index == 1) {
        this.status = 0;
      } else if (index == 2) {
        this.status = 1;
      }
      setTimeout(() => {
        that.getList();
      }, 500);
    },
    async getList() {
      let res = await request._taskIndex({
        page: this.page,
        userid: localStorage.getItem("userid"),
        status: this.status,
      });
      console.log("任务列表=", res);
      if (_.toInteger(res.code) === 1) {
        this.taskList = res.data.data;
      }
    },
    add() {
      var url;
      if (this.navIndex == 0) {
        url = "/CreateTask";
      } else {
        url = "/CreateRemind";
      }
      this.$router.push(url);
    },
    navClick(index) {
      this.navIndex = index;
      this.page = 1;
      index == 0 ? this.getList() : this.getMessList();
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  padding: 0 111px 0 127px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  font-size: 30px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #7d8393;
  background: #fff;
}

.navItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100px;
}

.navItem-name {
  margin-top: 34px;
}
.nav-underline {
  width: 91px;
  height: 6px;
  background: #0558f4;
  border-radius: 3px 3px 3px 3px;
  bottom: 0;
  position: absolute;
}

.select {
  width: 100%;
  padding: 36px 0 9px 30px;
}

.selectItem {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  width: 126px;
  height: 46px;
  background: #fff;
  margin-right: 16px;
}

.selctActive {
  color: #0558f4;
  background: #e0e9fa;
  border: 1px solid #0558f4;
}

.taskContent {
  width: 690px;
  height: 304px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  margin: 16px auto;
}

.taskContent-top {
  padding: 33px 0 25px 30px;
  border-bottom: 1px solid #d6e5ee;
}

.taskContent-top_title {
  font-size: 36px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.taskContent-top_btn {
  width: 79px;
  height: 36px;
  background: #0558f4;
  border-radius: 4px 4px 4px 4px;
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-left: 16px;
}

.taskContent-all {
  padding: 33px 30px 32px 30px;
}

.taskContent-all_title {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #101f48;
  margin-right: 16px;
}

.taskContent-all_btn {
  width: 85px;
  height: 35px;
  background: #edf3ff;
  border-radius: 4px 4px 4px 4px;
  font-size: 22px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.taskContent-icon {
  width: 12px;
  height: 20px;
  margin-left: 10px;
}

.taskContent-detail {
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #101f48;
}

.taskContent-time {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
  padding: 17px 0 8px 0;
}

.taskContent_time {
  font-size: 24px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #7d8393;
}

.taskContent-status {
  font-size: 28px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #0558f4;
}

.bottom {
  width: 100%;
  bottom: 0;
  left: 0;
  height: 151px;
  background: #ffffff;
  justify-content: center;
  border-top: 1px solid #c3c3c3;
}

.bottom-add {
  width: 670px;
  height: 80px;
  background: #0558f4;
  border-radius: 16px 16px 16px 16px;
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
  margin-top: 15px;
}

.padding-b {
  height: 300px;
}
</style>
